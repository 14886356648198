<template>
  <v-container>
    <h2 class="text-h2 pt-5">Scoubi-Doggle</h2>
    <h5 class="text-h5 pt-2 pb-7">CMS Login</h5>

    <v-form v-model="valid">
      <v-text-field
        v-model="username"
        :rules="usernameRules"
        label="Username"
        :disabled="isLoading"
        required
      ></v-text-field>

      <v-text-field
        v-model="password"
        :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
        :type="showPassword ? 'text' : 'password'"
        @click:append="showPassword = !showPassword"
        :rules="passwordRules"
        :counter="8"
        label="Password"
        :disabled="isLoading"
        required
      ></v-text-field>

      <v-btn
        class="mt-5"
        color="primary"
        v-if="!isLoading"
        :disabled="isLoading"
        @click="submitForm()"
      >
        Submit
      </v-btn>

      <v-progress-circular
        indeterminate
        color="primary"
        v-if="isLoading"
      ></v-progress-circular>
    </v-form>
    <v-snackbar v-model="snackbar">
      {{ errorText }}

      <template v-slot:action="{ attrs }">
        <v-btn color="blue" text v-bind="attrs" @click="snackbar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>


<script>
import { Auth } from "aws-amplify";
import router from "../../router/router";

export default {
  name: "LoginPage",

  data: () => ({
    isLoading: false,
    valid: false,
    showPassword: false,
    snackbar: false,
    errorText: "",
    username: "",
    usernameRules: [(v) => !!v || "Usernmae is required"],
    password: "",
    passwordRules: [
      (v) => !!v || "Password is required",
      (v) => v.length >= 8 || "Name must be at least 8 characters",
    ],
  }),

  methods: {
    submitForm() {
      if (this.valid) {
        this.signIn();
      }
    },
    async signIn() {
      try {
        this.isLoading = true;
        await Auth.signIn(this.username, this.password);
        const res = await Auth.currentSession();
        const accessToken = await res.getAccessToken();
        const token = await accessToken.getJwtToken();

        this.$store.dispatch({
          type: "login",
          username: this.username,
          token: token,
        });

        this.isLoading = false;

        router.push({ path: "/" });
      } catch {
        this.snackbar = true;
        this.errorText = 'Error signing in.'
        this.isLoading = false;
      }
    },
  },
};
</script>
