import Vue from 'vue'
import VueRouter from 'vue-router'
import LoginPage from '../components/LoginPage/LoginPage.vue'
import ProjectsPage from '../components/ProjectsPage/ProjectsPage.vue'
import ProjectEditorPage from '../components/ProjectEditorPage/ProjectEditorPage.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Projects Page',
    component: ProjectsPage,
    exact: true
  },
  {
    path: '/login',
    name: 'Login Page',
    component: LoginPage,
    exact: true
  },
  {
    path: '/projects/:projectId',
    name: 'Project Editor Page',
    component: ProjectEditorPage,
    props: true
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
