<template>
  <v-container>
    <v-btn text class="blue--text pa-0" @click="backClicked">
      <v-icon>mdi-arrow-left</v-icon>
      Back
    </v-btn>

    <h2 class="text-h2 pt-2">Project Editor</h2>
    <p class="text-body-1 pt-3 pb-2">Project id: {{ projectId }}</p>

    <v-progress-circular
      indeterminate
      color="primary"
      v-if="isLoading"
    ></v-progress-circular>

    <v-card elevation="2" class="pa-10 rounded-lg" v-if="!isLoading">
      <v-form v-model="valid">
        <h5 class="text-h5 font-weight-bold grey--text text--darken-3 pb-5">
          General Information:
        </h5>

        <v-text-field
          v-model="project.name"
          :rules="required"
          label="Project Name"
          :disabled="isLoading"
          required
        ></v-text-field>

        <v-text-field
          v-model="project.popularity"
          :rules="required"
          label="Popularity"
          :disabled="isLoading"
          required
        ></v-text-field>

        <v-select
          v-model="project.difficulty"
          :items="difficulties"
          :rules="required"
          label="Difficulty"
          required
        ></v-select>

        <img
          :src="project.image"
          class="max-height-200 rounded-lg mt-5"
          height="200"
          width="400"
        />
        <v-spacer></v-spacer>
        <input
          @change="imageChosen('image', 'project')"
          class="pt-1"
          type="file"
          ref="image"
          label="File input"
        />
        <v-text-field
          v-model="project.image"
          :rules="required"
          class="mt-4"
          label="Image"
          :disabled="isLoading"
          required
        ></v-text-field>

        <!-- STEPS -->
        <div
          class="my-10"
          v-for="(step, index) in project.steps"
          :key="step.image"
        >
          <v-divider class="mb-12"></v-divider>

          <v-btn icon class="float-right" @click="deleteStepClicked(index)">
            <v-icon class="red--text"> mdi-trash-can-outline </v-icon>
          </v-btn>

          <h5 class="text-h5 font-weight-bold grey--text text--darken-3 pb-6">
            Step {{ index + 1 }}:
          </h5>

          <img
            :src="step.image"
            class="max-height-200 rounded-lg"
            height="200"
            width="400"
          />
          <v-spacer></v-spacer>
          <input
            @change="imageChosen('step' + index.toString(), 'step', index)"
            class="pt-1"
            type="file"
            :ref="'step' + index.toString()"
          />

          <v-text-field
            v-model="step.image"
            :rules="required"
            class="mt-3"
            label="Step Image"
            :disabled="isLoading"
            required
          ></v-text-field>

          <v-textarea
            v-model="step.instructions"
            :rules="required"
            rows="3"
            class="mt-3"
            label="Instructions"
            :disabled="isLoading"
            filled
            auto-grow
            required
          ></v-textarea>
        </div>

        <v-btn class="mb-4" @click="newStepClicked">
          New Step
          <v-icon>mdi-plus-thick</v-icon>
        </v-btn>

        <v-divider class="mb-11"></v-divider>

        <div class="d-flex justify-end pr-10">
          <v-btn @click="backClicked()" class="mr-4">Cancel</v-btn>
          <v-btn
            color="primary"
            v-if="!isLoading"
            :disabled="isLoading"
            @click="saveClicked()"
          >
            Save
          </v-btn>
        </div>
      </v-form>
      <v-snackbar v-model="snackbar">
        {{ errorText }}

        <template v-slot:action="{ attrs }">
          <v-btn color="blue" text v-bind="attrs" @click="snackbar = false">
            Close
          </v-btn>
        </template>
      </v-snackbar>
    </v-card>
  </v-container>
</template>

<script>
import axios from "../../axios/axios";
import default_axios from "axios";
import router from "../../router/router";

export default {
  data: () => ({
    project: {
      name: "",
      image: "",
      popularity: "",
      difficulty: "",
      steps: [],
    },
    isLoading: true,
    valid: false,
    snackbar: false,
    errorText: "",
    required: [(v) => !!v || "required"],
    difficulties: ["Easy", "Medium", "Hard"],
  }),
  computed: {
    projectId() {
      return this.$route.params.projectId;
    },
    isCreatingNewProject() {
      return this.$route.query.create;
    },
    isLoggedIn() {
      return this.$store.getters.getIsLoggedIn;
    },
  },
  methods: {
    async getProject() {
      try {
        if (this.isLoggedIn) {
          const res = await axios.get(`/project/${this.projectId}`);
          this.project = res.data.Item;
          this.isLoading = false;
        } else {
          this.snackbar = true;
          this.errorText = "You are not logged in.";
        }
      } catch {
        this.snackbar = true;
        this.errorText = "Error loading project.";
      }
    },
    async imageChosen(ref, type, index) {
      try {
        if (this.isLoggedIn) {
          let file;

          if (type === "project") {
            file = this.$refs[ref].files[0];
          } else {
            file = this.$refs[ref][0].files[0];
          }

          let reader = new FileReader();
          let image = "";

          reader.onload = (i) => {
            image = i.target.result;
          };
          reader.readAsDataURL(file);

          const presignedUrl = await axios.get(`/upload`);
          console.log(presignedUrl);

          let binary = atob(image.split(",")[1]);
          let array = [];

          for (var i = 0; i < binary.length; i++) {
            array.push(binary.charCodeAt(i));
          }

          let blobData = new Blob([new Uint8Array(array)], {
            type: "image/jpeg",
          });

          const res = await default_axios.put(
            presignedUrl.data.uploadURL,
            blobData
          );

          if (type === "project") {
            this.project.image = res.config.url.split("?")[0];
          } else {
            this.project.steps[index].image = res.config.url.split("?")[0];
          }
        }
      } catch (err) {
        this.snackbar = true;
        this.errorText = "Error uploading file.";

        console.log(err);
      }
    },
    async saveClicked() {
      try {
        this.isLoading = true;

        if (this.isCreatingNewProject === "no") {
          let projectData = this.project;
          delete projectData.id;
          await axios.put(`/project/${this.projectId}`, projectData);
        } else {
          await axios.post(`/create-project`, this.project);
          router.push({ path: "/" });
        }

        this.isLoading = false;
      } catch {
        this.snackbar = true;
        this.errorText = "Error saving project.";
        this.isLoading = false;
      }
    },
    newStepClicked() {
      const blankStep = {
        instructions: "",
        image: "",
      };
      this.project.steps.push(blankStep);
    },
    deleteStepClicked(index) {
      if (confirm("Are you sure you want to delete this step?")) {
        this.project.steps.splice(index, 1);
      }
    },
    backClicked() {
      history.back();
    },
  },
  mounted() {
    if (this.isCreatingNewProject === "no") this.getProject();
    else this.isLoading = false;
  },
};
</script>

<style scoped>
.max-height-200 {
  max-height: 200px;
  width: auto;
}
</style>