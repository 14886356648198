import Vue from 'vue';
import App from './App.vue';
import vuetify from './plugins/vuetify';
import Amplify from 'aws-amplify';
import router from './router/router';
import store from './store/store';

Amplify.configure({
  Auth: {
      region: 'us-east-2',
      userPoolId: 'us-east-2_bfRQVeBcm',
      userPoolWebClientId: '4lhuq8110ggjq4dg8p3p9sk3nq',
  }
});

Vue.config.productionTip = false

new Vue({
  vuetify,
  router,
  store,
  render: h => h(App)
}).$mount('#app')
