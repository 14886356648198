<template>
  <v-container>
    <v-btn class="float-right mt-2" @click="logout">Logout</v-btn>
    <p class="float-right text-body-1 pb-2 pt-4 px-2">
      Logged in as: {{ username }}
    </p>

    <h2 class="text-h2 pb-5 pt-5">Projects</h2>

    <v-progress-circular
      indeterminate
      color="primary"
      v-if="isLoading"
    ></v-progress-circular>

    <div class="d-flex justify-end">
      <v-btn @click="newProjectClicked"> <v-icon>mdi-plus-thick</v-icon></v-btn>
    </div>

    <v-card elevation="1" v-if="!isLoading" class="rounded-lg overflow-hidden">
      <v-simple-table>
        <template v-slot:default>
          <thead>
            <tr class="blue-grey darken-1">
              <th class="text-body-2 text-left white--text font-weight-light">
                No.
              </th>
              <th class="text-body-2 text-left white--text font-weight-light">
                Project Name
              </th>
              <th class="text-body-2 text-left white--text font-weight-light">
                Popularity
              </th>
              <th>
                <v-icon class="grey--text text--lighten-1 float-right pr-2">
                 mdi-trash-can-outline
                </v-icon>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(project, index) in projects"
              :key="project.id"
              class="pointer"
            >
              <td @click="onProjectClick(project, index)">{{ index + 1 }}</td>
              <td @click="onProjectClick(project, index)">
                {{ project.name }}
              </td>
              <td @click="onProjectClick(project, index)">
                {{ project.popularity }}
              </td>
              <td>
                <v-btn
                  icon
                  class="float-right"
                  @click="deleteProjectClicked(project.name, project.id)"
                >
                  <v-icon class="red--text">mdi-trash-can-outline</v-icon>
                </v-btn>
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-card>
    <v-snackbar v-model="snackbar">
      {{ errorText }}
      <template v-slot:action="{ attrs }">
        <v-btn color="blue" text v-bind="attrs" @click="snackbar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>


<script>
import axios from "../../axios/axios";
import router from "../../router/router";

export default {
  data: () => ({
    isLoading: true,
    projects: [],
    snackbar: false,
    errorText: "",
  }),

  computed: {
    isLoggedIn() {
      return this.$store.getters.getIsLoggedIn;
    },
    username() {
      return this.$store.getters.getUsername;
    },
  },

  methods: {
    logout() {
      this.$store.dispatch({
        type: "logout",
      });
    },
    async getProjects() {
      try {
        if (this.isLoggedIn) {
          const res = await axios.get("/projects");
          this.projects = await JSON.parse(res.data.body);
          this.isLoading = false;
        } else {
          this.snackbar = true;
          this.errorText = "You are not logged in.";
        }
      } catch {
        this.snackbar = true;
        this.errorText = "Error loading projects.";
      }
    },
    onProjectClick(project) {
      router.push({ path: `/projects/${project.id}`, query: { create: "no" } });
    },
    newProjectClicked() {
      router.push({ path: `/projects/new`, query: { create: "yes" } });
    },
    async deleteProjectClicked(name, id) {
      if (confirm(`Are you sure you want to delete project named: ${name}?`)) {
        try {
          const res = await axios.delete(`/project/${id}`);
          this.snackbar = true;
          this.errorText = res.data;
          this.getProjects();
        } catch {
          this.snackbar = true;
          this.errorText = "Error deleting project.";
        }
      }
    },
  },

  mounted() {
    this.getProjects();
  },
};
</script>


<style scoped>
.pointer {
  cursor: pointer;
}
</style>