<template>
  <v-app>
    <router-view />
  </v-app>
</template>

<script>
import router from "./router/router";

export default {
  name: "App",
  methods: {
    autoLogin() {
      const expirationTime = new Date(this.expiration).getTime();
      const now = new Date().getTime();

      if (expirationTime > now) {
        this.$store.dispatch("autoLogin");
      } else {
        if (this.$route.path != "/login") router.push({ path: "/login" });
      }
    },
  },
  computed: {
    isLoggedIn() {
      return this.$store.getters.getIsLoggedIn;
    },
    username() {
      return this.$store.getters.getUsername;
    },
    token() {
      return this.$store.getters.getToken;
    },
    expiration() {
      return this.$store.getters.getExpiration;
    },
  },
  beforeMount() {
    this.autoLogin();
  },
};
</script>
